*, ::after, ::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  overflow: hidden;
}

html {
  font-family: 'Cabin', sans-serif;
  padding: 0;
  margin: 0;
  color: white;
}

html.enable-scroll {
  height: 100%;
  position: static !important;
}

html.enable-scroll body {
  overflow: auto !important;
}

a-scene {
  display: inline;
}

a {
  color: white;
}

input, select, textarea, button{
  font-family:inherit;
}

a:active,
a:visited {
  color: inherit;
}

h1 {
  font-size: 32px;
  font-weight: bold;
  margin: 10% 0 0 0;
  margin: 0;
  margin-bottom: 10%;
}

h2 {
  font-size: 24px;
  font-weight: normal;
  padding: 2% 0 3% 0;
}

h3 {
  font-size: 18px;
  font-weight: normal;
  padding: 2% 0 3% 0;
  margin: 0;
}

h4 {
  font-size: 14px;
  font-weight: normal;
}

.switch-button {
  background: rgba(255, 255, 255, 0.56);
  border-radius: 30px;
  overflow: hidden;
  width: 80px;
  text-align: center;
  font-size: 12px;
  letter-spacing: 1px;
  color: black;
  position: relative;
  padding-right: 40px;
  position: relative;
  margin: auto;
}

.switch-button:before {
  content: "2D";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
}

.switch-button-checkbox {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80%;
  opacity: 0;
  z-index: 2;
}

.switch-button-checkbox:checked + .switch-button-label:before {
  transform: translateX(40px);
  transition: transform 100ms linear;
}

.switch-button-checkbox + .switch-button-label {
  position: relative;
  padding: 8px 0;
  display: block;
  user-select: none;
  pointer-events: none;
}

.switch-button-checkbox + .switch-button-label:before {
  content: "";
  background: #fff;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 20px;
  transform: translateX(0);
  transition: transform 100ms;
}

.switch-button-checkbox + .switch-button-label .switch-button-label-span {
  position: relative;
}

.nft-list-view {
  position: relative;
  padding:0;
  margin:0;

  top:0;
  left:0;

  width: 100%;
  min-height: 100%;
  background: black;

  z-index: 999999;

  padding-top: 10%;
}

.nft-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 6fr));
  grid-template-rows: auto auto auto minmax(1px, 1fr);
  grid-row-gap: 4rem;
  row-gap: 4rem;
  grid-column-gap: .5rem;
  column-gap: 5rem;
  margin: auto;
  padding-bottom: 10rem;
  justify-items: center;
  background-color: black;
  padding: 0 130px 100px 160px;
  max-width: 1400px;
}

.nft-image {
  position: relative;
  place-self: center;
}

.nft-info {
  position: absolute;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.8);
  left:  0px;
  right:  0px;
  height: 65px;
  padding: 10px;
  font-size: 18px;
}

.nft-collection {
  color: #cdc4ff;
}

.collection-select {
  position: absolute;
  top: 25px;
  left: calc(50% - 110px);
  width: 220px;
  border: 1px solid #e8eaed;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 1px 3px -2px #9098a9;
  padding: 7px 40px 7px 12px;
  color: black;

  /* needed */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* SVG background image */
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='50' fill='%23000000'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
  background-size: 12px;
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
  background-color: #efefef;
}


.nft-list-view-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  z-index: 999998;
}

.single-nft-view {
  top: 0px;
  position: fixed;

  height: 100%;
  width: 100%;
  background-color: black;
 
  text-align: center;

  justify-content : space-around; 
  align-items: center; 

  z-index: 9999999;
}

.single-nft-view-container {
  width: 25%;
  margin: auto;
  margin-top:  10%;
}

.single-nft-view img {
  display: block;
  width: 100%;
}

.single-nft-view p {
  display: inline-block;
  text-align: left;
  width: 100%;
}

.nft-image img {
  width:  275px;
  max-width: 100%;
  height: auto;
}

.or {
  font-size: 16px;
  margin: 0 15px 0 15px;
}

.home-menu {
  margin-top: 15px;
}

.home h1 {
  font-size: 64px;
  margin-bottom: 2%;
}

.home h2 {
  font-size: 24px;
}

.home .copyright {
  position: absolute;
  font-size: 14px;
  bottom: 0;
  left: calc(50% - 225px);
  opacity: 0.5;
  width: 450px;
}

.wallet-value {
  font-size: 14px;
}

.menu {
  position: fixed;
  right: 0;
  top: 25px;
  right: 25px;
}

.user-menu,
.navigation-arrow {
  opacity: 1;
  transition: opacity 0.5s ease-out;
  margin-top: 5px;
}

.menu .link {
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: white;
  z-index: 9999;
  font-weight: normal;
  font-size: 20px;
  cursor: pointer;
  margin-right: 2vw;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
}

.menu .link:after {
  position: relative;
  display: block;
  content: "";
  top: 100%;
  left: 0;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(20deg, #F2F627, #5294D1, #B41F78);
}

.signup-username {
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 25px;
}

.signup-username.user-available {
  background-image: url(/assets/images/usernameSuccess.png);
}

.signup-username.user-taken {
  background-image: url(/assets/images/usernameError.png);
}

.community {
  display: inline-block;
  position: absolute;
  bottom: 40px;
  right: 20px;
  text-align: right;
}

.community .icons {
  display: inline-flex;
  flex-direction: row;
  margin-top: 5px;
}

.community .icons a {
  display: inline-block;
}

.community .icons h3 {
  display: inline-block;
  line-height: 36px;
  font-size: 16px;
  padding: 0;
  margin: 0 25px 0 10px;
}

.community .icons a:last-child h3 {
  margin: 0 0 0 10px;
}

.community .icons img.icon {
  flex: 1 1 0px;
  width: 36px;
  vertical-align: bottom;
}

button.demo {
  font-weight: lighter;
  width: 150px;
  border: 2px solid transparent;
}

button.demo:hover {
  border: 2px solid white;
}

button.simple.wallet {
  position: relative;
  width: auto;
}

button.simple.wallet::after {
  position: absolute;
  font-size: 12px;
  font-weight: lighter;
  left: 5px;
  top: 35px;
  content: '*More NFTs marketplaces supported soon';
}

section {
  display: block;
  position: relative;
  z-index: 99999;
  background-color: #090a05;
  min-height: 100%;
  width: 100%;
}

.intro img {
  display: block;
  margin: auto;
}

.alogo {
  display: inline-block;
  top: 45px;
  width: 60px;
  margin-left: calc(50% - 30px);
  padding: 10px 0 10px 0;
}

.backgroundimage {
  min-height: 100%;
  min-width: 1920px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
}

.backgroundimage.portrait {
  display: none;
}

section.home {
  background: url(/assets/images/homeBackgroundLandscape.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

section.login,
section.signup,
section.forgot-password {
  background: url(/assets/images/framesBackground.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

section.user-settings {
  background: url(/assets/images/settingsBackground.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

section.user-settings .section {
  text-align: left;
  border-bottom: 1px solid white;
  padding: 20px 0 20px 0;
}

section.user-settings .section:last-child {
  border-bottom: 0;
}

section.user-settings .spaceurl {
  color: #757478;
  margin: 0;
}

section.user-settings .spaceurl .username {
  color: white;
}

section.user-settings .opensealogo {
  width: 120px;
  margin-left: 5px;
  margin-top: 5px;
}

section.error-user-name {
  background: url(/assets/images/errorBackground.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

section.info
{
  background: url(/assets/images/loadingScreenBackground.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.info .content,
.error-user-name .content {
  padding-top: 200px;
  width: 80%;
  height: 50%;
  margin: auto;
  text-align: center;
}

.info .content h1,
.error-user-name .content h1 {
  margin-bottom: 20px;
}

.info .content h2,
.error-user-name .content h2 {
  padding: 0;
}

.info .alogo,
.error-user-name .alogo {
  margin: 0;
  width: 75px;
}

.login-error {
  display: inline-block;
  width: 100%;
  margin-top: 20px;
  line-height: 45px;
  font-size: 16px;
  border-radius: 10px;
  background-color: #ffe3e6;
  border: 1px solid red;
  color: rgb(36, 41, 46);
}

section.signup .alogo {
  top: 15px;
}

section.signup .signup-form {
  top: 85px;
}

section.login button {
  margin: 25px 0 25px 0;
}

section.login h1 {
  margin-bottom: 0;
}

.home .copy {
  position: absolute;
  display: inline-block;
  width: 600px;
  left: 150px;
  top: 200px;
}

.home .artuego {
  margin-bottom: 5%;
  width: 240px;
}


input[type="submit"],
.button, button {
  display: inline-block;
  font-weight: bold;
  color: white;
  padding: 0 10px 0 10px;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: linear-gradient(45deg, #f9bb14, #5294D1, #B41F78);
  background-repeat: no-repeat;
  background-clip: padding-box;
  border: 2px solid transparent;
  font-size: 18px;
  line-height: 45px;
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
  font-family: 'Cabin', sans-serif;
}

.subscribe input[type="submit"],
.subscribe input[type="text"] {
  margin-top: 10px;
}

.subscribe input[type="text"] {
  width: 190px;
}

.spam {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
}

button:hover {
  border: 2px solid white;
}

button.simple {
  width: 100%;
  background: none;
  border: 2px solid white;
}


button.simple:hover {
  border-color: pink;
}

button:disabled {
  background-color: gray;
  pointer-events: none;
}

.button--loading::after {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: 10px;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@media screen and (max-width: 1200px) {
  .home .copy {
    width: 450px;
  }

  .home h1 {
    font-size: 46px;
  }

  .home .artuego {
    width: 180px;
  }

  .home h2 {
    font-size: 18px;
  }
}

@media screen and (max-width: 1000px) {
  
  .home .copy {
    left: 100px;
  }

  .nft-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    padding: 0 100px 0 16 0px;
  }

  .nft-image img {
    width: 400px;
  }

}

@media screen and (min-width: 1800px) {
  .home .copy {
    width: 37%;
    top: calc(50% - 10vw);
  }

  .nft-image img {
    width: 4  00px;
  }
}

@media screen and (max-width: 600px) {
  .collection-select {
    top: 80px;
  }

  .nft-grid {
    padding-top: 100px;
  }

  .home h1 {
    font-size: 34px;
  }

  .home h2 {
    font-size: 16px;
  }

  .button, button {
    font-size: 14px;
    padding: 0 5px 0 5px;
  }

  button.demo {
    width: 120px;
  }

  .info .content h1,
  .error-user-name .content h1 {
    font-size: 20px;
  }

  .community {
    bottom: 42px;
    right: auto;
    text-align: center;
    width: 100%;
    margin: auto;
  }

  .community h3 {
    padding: 0;
    margin-bottom: 10px;
  }

  .community .icons h3 {
    display: none;
  }

  .community .icon {
    margin: 0 15px 0 15px;
  }

  .home .copyright {
    font-size: 11px;
    width: 380px;
    left: calc(50% - 170px);
  }

  button.simple.wallet::after {
    font-size: 9px;
  }
}

@media screen and (max-width: 600px) {

  section.home {
    background: url(/assets/images/homeBackgroundPortrait.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .home .frame {
    top: 15vw;
    width: 80vw;
    left: calc(50% - 40vw);
  }

  .home .artuego {
    position: fixed;
    left: 30%;
    width: 40%;
    top: 10%;
  }

  .home .text {
    position: fixed;
    bottom: 22%;
    left: 2%;
    text-align: center;
    width: 96%;
  }

  .menu {
    top: 10px;
    right: 10px;
  }

  .menu .link {
    font-size: 4.6vw;
  }

}

.overlay {
  z-index: 99999;
}

.close-nft-button {
  height: 30px;
  line-height: 30px;
  width: 30px;
  margin-left: 12px;
  background-color: black;
  Z-index: 1;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
  background-color: white;
}


.close-nft-button::before {
  content: 'x'; 
  color: black;
  background-color: white;
  font-weight: 300;
  font-family: Arial, sans-serif;
}

.watermark,
.ui,
.nftinfo,
.nftmenu,
.navigation-arrow {
  position: fixed;
  display: inline-block;
}

.ui {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.nftmenu a {
  text-decoration: none;
}

.fullscreen-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.watermark,
.navigation-arrow,
a.opensea,
.share {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}

.watermark, .ui {
  z-index: 9999999;
}

.navigation-arrow {
  opacity: 0.3;
  outline: none;
  height: 100px;
  top: calc(50% - 50px);
  cursor: pointer;
}

.navigation-arrow:hover {
  opacity: 1.0;
}

.navigation-arrow.back {
  left: 0;
}

.navigation-arrow.forward {
  right: 0;
}

.watermark {
  top: 20px;
  left: 30px;
  text-align: center;
}

.ui {
  top: 20px;
  right: 30px;
}

.ui h1,
.ui h2 {
  font-size: 18px;
  margin: 0;
  padding: 2px;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
}

.ui h2 {
  font-size: 14px;
}

.user-space-name,
.user-space-slash,
.user-space-slash a {
  color: rgba(255, 255, 255, 0.7);
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
}

.user-space-name, .user-space-slash a {
  text-decoration: none;
  color: rgba(255, 255, 255, 1.0);
}

.watermark .logo {
  width: 74px;
  margin: 0 auto;
}

.watermark h2 {
  font-size: 18px;
  opacity: 0.7;
  padding: 0;
  margin: 0;
}

.nftmenu {
  bottom: 20px;
  right: 20px;
}

a.opensea img {
  width: 160px;
  height: auto;
}

.watermark img {
  width:  70%;
  height:  auto;
}

.icon {
  display: inline-block;
  position: relative;
}

.icon img {
  width: 32px;
  cursor: pointer;
}

.icon-container .tooltiptext,
.spacelink .tooltiptext,
.icon .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: white;
  color: #333333;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 1;
  opacity: 1.0;
  top: 100%;
  left: 50%;
  margin-left: -90px;
}

.icon .tooltiptext.logged-user {
  margin-left: -60px;
}

.icon-container {
  position: relative;
}

body.mode2d .collection-menu {
  display: none;
}

.collection-menu,
.collection-title {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}

.collection-menu .icon,
.collection-title .icon {
  position: relative;
  width: 36px;
  height: 36px;
  clip-path: circle(15px at center);
  background: rgba(255, 255, 255, 0.2);
}

.collection-title .icon {
  background: rgba(255, 255, 255, 0.8); 
}

.collection-menu .icon:hover {
  background: rgba(255, 255, 255, 0.8);
}

.collection-menu .icon img,
.collection-title .icon img {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 2px;
  left: 2px;
  clip-path: circle(12px at center);
}

.collection-title .title {
  line-height: 36px;
  margin-left: 5px;
}

.collection-title .icon {
  pointer-events: none;
}

.icon.meebits img {
  content: url(/assets/icons/meebits.png);
}

.icon.bayc img {
  content: url(/assets/icons/bayc.png);
}

.icon.veefriends img {
  content: url(/assets/icons/veefriends.png);
}

.icon.zenft img {
  content: url(/assets/icons/zenft.gif);
}

.icon.cryptopunks img {
  content: url(/assets/icons/cryptopunks.png);
}

.icon.artblocks img {
  content: url(/assets/icons/artblocks.png);
}

.icon.craniums img {
  content: url(/assets/icons/craniums.png);
}

.icon.kennelclub img {
  content: url(/assets/icons/kennel-club.png);
}

.icon.bullsontheblock img {
  content: url(/assets/icons/bullsontheblock.jpeg);
}

.icon.guttercatgang img {
  content: url(/assets/icons/guttercatgang.png);
}

.icon.alienboy img {
  content: url(/assets/icons/alienboy.gif);
}

.icon.sorare img {
  content: url(/assets/icons/sorare.jpeg);
}

.spacelink {
  position: relative;
}

.spacelink .tooltiptext {
  left: 50%;
  top: 90%;
  opacity: 1.0;
}

.spacelink:hover .tooltiptext,
.icon:hover .tooltiptext,
.icon-container:hover .tooltiptext {
  visibility: visible;
}

.icon-container .tooltiptext::after,
.spacelink .tooltiptext::after,
.icon .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 50%;
  margin-left: 15px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.nftinfo {
  font-family: 'Josefin Sans', sans-serif;
  bottom: 20px;
  left: 50px;
}

.nftinfo h1 {
  font-size: 32px;
  text-shadow: 2px 2px #000000;
  margin-bottom: 5px;
}

.nftinfo .title-container {
  display: flex;
  align-items: center;
  margin: 10px 0 0 0;
}

.nftinfo .tooltiptext {
  font-family: 'Cabin', sans-serif;
  top: 38px;
  right: -25px;
  left: auto;
}

.nftinfo h1.title {
  display: inline;
}

.nftinfo .icon {
  position: relative;
  margin-left: 5px;
}

.nftinfo h1.collection {
  font-weight: lighter;
  font-style: italic;
}

.form-input {
  text-align: left;
}

.button.login-button,
.button.signup-button {
  display: block;
  margin-top: 20px;
}

.user-settings-modal,
.signup-form,
.signin-form,
.forgot-password-form,
.create-account,
.signin-account,
.signout-account {
  display: inline-block;
  margin-left: calc(50% - 260px);
  width: 520px;
}

.signup-form p {
  margin-top: 2px;
}

.create-account,
.signin-account,
.signout-account {
  width: 300px;
  top: 715px;
  text-align: center;
  margin-left: calc(50% - 150px);
  background-color: #1c171e;
  background: rgba(28, 23, 30, 0.8);
  margin-top: 10px;
  padding: 20px;
}

.signout-account {
  padding: 10px;
  background-color: rgba(29, 36, 14, 0.8);
}

.create-account {
  top: 650px;
  text-align: center;
}

.create-account h3 {
  padding-bottom: 0;
}

.user-settings-modal,
.signup-form,
.signin-form,
.forgot-password-form {
  top: 125px;
  text-align: left;

  background:

  /*First border*/
  linear-gradient(#e7f034,#e7f034) 0 100%,
  linear-gradient(#e7f034,#e7f034) 0 0,
  linear-gradient(#e7f034,#e7f034) 0 0,
  linear-gradient(#e7f034,#e7f034) 100% 0,
  /*Second border*/
  linear-gradient(#96be89,#96be89) 0 calc(100% - 4px),
  linear-gradient(#96be89,#96be89) 4px 0,
  linear-gradient(#96be89,#96be89) 0 4px,
  linear-gradient(#96be89,#96be89) calc(100% - 4px) 0,
  /*third border*/
  linear-gradient(#5d87c7,#5d87c7) 0 calc(100% - 8px),
  linear-gradient(#5d87c7,#5d87c7) 8px 0,
  linear-gradient(#5d87c7,#5d87c7) 0 8px,
  linear-gradient(#5d87c7,#5d87c7) calc(100% - 8px) 0,
  /*fourth border*/
  linear-gradient(#b41f79,#b41f79) 0 calc(100% - 12px),
  linear-gradient(#b41f79,#b41f79) 12px 0,
  linear-gradient(#b41f79,#b41f79) 0 12px,
  linear-gradient(#b41f79,#b41f79) calc(100% - 12px) 0;
  /*And so on ...*/
  background-size:100% 4px,4px 100%;
  background-repeat:no-repeat;

  padding: 16px;
}

.user-settings-modal-content,
.form-content {
  padding: 32px 75px 20px 75px;
  width: 100%;
  height: 100%;
  background-color: #1c171e;
  text-align: center;
}

.user-settings-modal-content {
  background-color: #1D240E;
}

section.signup .form-content {
  padding-top: 15px;
}

.forgot-password-form {
  text-align: center;
}

.welcome-ui {
  width: 100%;
}

.forgot-password-link {
  text-decoration: none;
  color: white;
  text-align: right;
  text-decoration: underline;
}

.forgot-password-link a {
  color: white;
}

.forgot-password-link a:active,
.forgot-password-link a:visited
{
  color: inherit;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.connectwallet {
  margin: 0;
}

.buttons {
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
}

input[type="text"],
input[type="email"],
input[type="password"]
{
  display: inline-block;
  padding: 10px;
  width: 60%;
  outline: none;
  border: 0;
  background-color: #fff !important;
  vertical-align: middle;
  border: #7AA5F2 solid 2px;
}

input[type="text"],
input[type="submit"],
input[type="email"],
input[type="password"]
{
  box-sizing: border-box;
  height: 45px;
  font-size: 16px;
  border-radius: 10px;
  margin-top: 40px;
}

.signup-form h1 {
  margin-bottom: 10px;
}

.signup-form h4 {
  margin-bottom: 0;
}

.signup-form input[type="text"],
input[type="email"],
input[type="password"],
button[type="submit"] {
  width: 100%;
}

.signup-form input[type="text"],
.signup-form input[type="email"],
.signup-form input[type="password"],
.signup-form button[type="submit"] {
  margin-top: 10px;
}

.forgot-password button[type="submit"] {
  margin-top: 20px;
}

.forgot-password .email-remainder {
  text-align: left;
}

.error {
  color: #FF6B00;
}

.ok {
  color: #7AA5F2;
}

input.error {
  border: 4px #FF6B00 solid;
}

input.ok {
  border: 4px #7AA5F2 solid;
}

.hidden {
  display: none;
}

.hidden-animated {
  transition: opacity 1s ease-out;
  opacity: 0;
  height: 0;
}

@media screen and (min-width: 1600px) {
  .button.demo {
    width:  175px;
  }

  .home .copy {
    top: calc(50% - 11vw);
    left:  300px;
  }

  .home .artuego {
    width:  280px;
  }

  .home h1 {
    font-size: 75px;
  }

  .home h2 {
    font-size: 28px;
  }

  .ui h1, .ui h2 {
    font-size: 24px;
  }

  a.opensea img {
    width: 220px;
  }

  .watermark h2 {
    font-size: 22px;
  }

  .icon img {
    width: 40px;
  }

  input[type="submit"], .button, button {
    line-height:  50px;
    font-size: 18px;
  }

}

@media screen and (max-height: 700px) {
  .home .copy {
    top: 130px;
  }

  .signup-form,
  .signin-form,
  .forgot-password-form {
    width: 95%;
    top: 0px;
    margin: 0 auto;
    display: block;
  }

  .info .content,
  .error-user-name .content {
    padding-top: 110px;
    width: 80%;
    height: 50%;
    margin: auto;
    text-align: center;
  }

  .create-account h4 {
    font-size: 14px;
  }

  .form-content {
    padding: 40px 100px 40px 100px;
  }

  /* .signup .alogo,
  .login .alogo {
    display: none;
  } */

  input[type="text"],
  input[type="submit"],
  input[type="email"],
  input[type="password"]
  {
    margin-top: 10px;
  }

  section.login button {
      margin: 10px 0 10px 0;
  }

  /* h4 {
    margin: 0;
  } */

  section.login h1 {
    margin-bottom: 10px;
  }

  .create-account {
    top: 400px;
  }

  .home .artuego {
    margin-bottom: 10px;
    width: 150px;
  }

  .nftinfo {
    bottom: 15px;
    left: 20px;
    width: 70%;
  }

  .ui {
    top: 15px;
  }

  .nftinfo h1 {
    font-size: 24px;
  }

  .nftmenu {
    bottom: 10px;
    right: 15px;
  }

  .watermark {
    top: 20px;
  }

  .watermark .logo {
    width: 50px;
  }
}

@media screen and (max-height: 600px) {
  .home .copy {
    top: 75px;
    width: 450px;
  }

  .home .copy h1 {
    font-size: 40px;
  }

  .home .copy h2 {
    font-size: 16px;
  }

  .create-account h4 {
    font-size: 14px;
  }

}

@media screen and (max-width: 600px) {

  .signup-form,
  .signin-form,
  .forgot-password-form,
  .create-account {
    top: 75px;
    width: 95%;
    margin: 0 auto;
    display: block;
  }

  .user-settings-modal {
    width: 95%;
    margin: 0 auto;
    display: block;
  }

  .user-settings-modal-content {
    padding: 15px;
  }

  .create-account {
    top: 475px;
  }

  .alogo {
    left: calc(50% - 25px);
    width: 50px;
    top: 10px;
  }

  /* .signup .alogo {
    display: none;
  } */

  section.signup .signup-form {
    top: 15px;
  }

  .form-content {
    padding: 20px 25px 20px 25px;
  }

  .form-content h1 {
    font-size: 24px;
  }

  .form-content h3 {
    font-size: 14px;
  }

  .watermark {
    top: 5px;
  }

  .nftinfo {
    bottom: 70px;
    left: 20px;
  }

  .ui {
    top: 15px;
  }

  .nftinfo h1 {
    font-size: 18px;
    text-shadow: 2px 2px #000000;
    margin-bottom: 5px;
  }

  .watermark .logo {
    width: 50px;
  }

  .watermark h2 {
    font-size: 16px;
  }

  .nftmenu {
    bottom: 5px;
    left: 10px;
  }

}

@media screen and (max-height: 400px)
{
  .community {
    bottom: 10px;
    right: 10px;
  }
  
  .home .copyright {
    font-size: 10px;
    bottom: 5px;
    left: 3%;
  }
  
  .community h3 {
    font-size: 12px;
  }

  .community .icons h3 {
    display: inline-block;
    line-height: 24px;
    margin: 0 15px 0 15px;
  }

  .community .icons img.icon {
    width: 24px;
  }

  .home .artuego {
    width: 130px;
  }

  .home .copy {
    width: 450px;
    top: 50px;
  }

  .home .copy h1 {
    font-size: 28px;
  }

  .home .copy h2 {
    font-size: 14px;
  }

  .home-menu {
    margin-top: 8px;
  }

  .button, button {
    font-size: 14px;
    line-height: 38px;
  }

  .button.demo {
    width: 120px;
  }

  button.simple.wallet::after {
    font-size: 9px;
    left: 2px;
  }

}

button.simple.go-home,
button.simple.user-settings-button {
  width: 150px;
}

/** Ellipsis animation **/
.one {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.0s;
  animation: dot 1.3s infinite;
  animation-delay: 0.0s;
}

.two {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.2s;
  animation: dot 1.3s infinite;
  animation-delay: 0.2s;
}

.three {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.3s;
  animation: dot 1.3s infinite;
  animation-delay: 0.3s;
}

@-webkit-keyframes dot {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@keyframes dot {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}